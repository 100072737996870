import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import Aarrow from '../assets/svg/arquetipo-arrow.svg'

const SuccessPage = () => (
  <Layout>
    <div id='success'>
      <h1>Your message has been sent</h1>
      <p>We will contact you as soon as possible</p>
      <Link className='cta' to='/'>Back Home<Aarrow/></Link>
    </div>
  </Layout>
)

export default SuccessPage
